<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
               
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/aboutus" class="active"><i class='bx bxs-user'></i> Founder</a></li>
                <li><a routerLink="/chairman"><i class='bx bx-user'></i> Chairman </a></li>
                <li><a routerLink="/ceo"><i class='bx bx-user'></i> Correspondent</a></li>
                <li><a routerLink="/sec"><i class='bx bx-user'></i> Secretary</a></li>
                
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img src="assets/img/instructor/instructor0.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>V. Michael</h3>
                        <p>Founder</p>
                        <p><strong>"Learning is eternal and experience is valuable."</strong></p>
                        <p align="justify">Our founder "Kalvi Rathna" Late Ln. Dr. V. Michael., M.I.S.T.E., Ph.D (USA) deserves the due homage of immortals for he lives and reigns in the hearts of many through his service by education.</p>
                        <p align="justify">He founded the St. Michael Educational and Charitable Trust in the year 1985 with a vision to offer quality based technical education to those who were deprived due to poverty, social status and many other such factors. He was committed to bring equality of opportunity despite a student coming from rural background. Though he could vividly foresee the less advantaged aspects of establishing a college in rural setting, he vowed to start one in a serene place far away from the hustle and bustle of city.</p>

                        
                    </div>
                </div>
            </div>
        </div>

        <section class="story-area ptb-100">
        <app-our-story></app-our-story>
    </section>
    
    <section class="funfacts-style-two ptb-70">
        <div class="container">
            <app-funfacts></app-funfacts>
        </div>
    
        <div id="particles-js-circle-bubble"></div>
    </section>
    
 

    </div>
</section>