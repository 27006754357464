<section class="about-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title"></span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>Welcome to
                        Fatima Michael College of Engineering and Technology</h6>
                    <p align="justify">Fatima Michael College of Engineering and Technology was established in the year 2009, by the renowned educationist and philanthropist <strong>Ln. Dr. M. Stalin Arockiaraj,</strong> Chairman  of St. Michael Group of Institutions. The college is affiliated to Anna University, Chennai and approved by AICTE, New Delhi.</p>
                    <p align="justify">FMCET relentlessly strives for academic excellence with technical orientation. The main objective of our college is to optimize the innovative skills and individuality of the students to be acknowledged in this competitive world.</p>
                   
                    
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/about4.jpg" alt="image">
                    <img src="assets/img/about/about2.jpg" alt="image">
                    
                 
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-4"></div>
</section>