<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title"><Strong>About Us</Strong></span>
                    <h2>"Learning is eternal and experience is valuable"</h2>
                    <h4>History</h4>
                    <p align="justify">Our founder "KalviRathna" Late Ln. Dr. V. Michael., M.I.S.T.E., Ph.D (USA) deserves the due homage of immortals for he lives and reigns in the hearts of many through his service by education.</p>
                    <div class="features-text">
                     <!--   <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>-->
                        <p align="justify">He founded the St. Michael Educational and Charitable Trust in the year 1985 with a vision to offer quality based technical education to those who were deprived due to poverty, social status and many other such factors. He was committed to bring equality of opportunity despite a student coming from rural background. Though he could vividly foresee the less advantaged aspects of establishing a college in rural setting, he vowed to start one in a serene place far away from the hustle and bustle of city.</p>
                    </div>
                   <!-- <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/about4.jpg" alt="image">
                    <img src="assets/img/about/about3.jpg" alt="image">
                    <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="particles-js-circle-bubble-4"></div>
</section>


<section class="story-area ptb-100">
    <app-our-story></app-our-story>
</section>

<section class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>

    <div id="particles-js-circle-bubble"></div>
</section>

<section class="values-area ptb-100">
    <app-our-values></app-our-values>
</section>

<section class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Management</h2>
            
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</section>



