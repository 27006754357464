<app-header-style-one></app-header-style-one>

<app-homefour-main-banner></app-homefour-main-banner>
<app-boxes></app-boxes>

<section class="partner-area pt-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</section>


<section class="partner-area pt-100">
<app-offer></app-offer>
</section>

<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Management</h2>
            <p>Our policy that encourages transparency, trust, openness, communication, feedback, and discussion among management people and parents.</p>
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>



<app-become-instructor-partner></app-become-instructor-partner>
