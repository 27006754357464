<app-header-style-one></app-header-style-one>
<!--
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses List</h2>
        </div>
    </div>
</div>-->

<section class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
        

                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                          

                            <div class="col-lg-5 col-md-6">
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-1">
                                        <img src="assets/img/courses/courses1.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>Dr. Mr.Saravana Kumar</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Automobile </a></h3>
                               
                                        <p align="justify">The department is steered smoothly by well qualified faculy members with proven ability in administrative, research and industrial backgrounds.

                                        </p>
                                    </div>
        .
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i>   4 Years
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> Intake : 60
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-2">
                                        <img src="assets/img/courses/courses2.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Civil Engineering</a></h3>
                                      
                                        <p>Our Civil Engineering students are successfully placed in leading MNC companies with good career prospects</p>
                                    </div>
        
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i> 150 students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> 4 Years
                                            </li>
                                            <li class="courses-price">
                                                60 Intake
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-3">
                                        <img src="assets/img/courses/courses3.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>James Anderson</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Computer Science Engineering</a></h3>
                             
                                        <p>Contemporary industry oriented curriculum with specializations in current and emerging trends in IT/Computing with major and minor - technology and domain specializations.

                                        </p>
                                    </div>
        
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i> 255 students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> 4 Years
                                            </li>
                                            <li class="courses-price">
                                              60 Intake
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-4">
                                        <img src="assets/img/courses/courses4.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>Liam King</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Electronics & Communication Engineering</a></h3>
                                       
                                        <p>Electronics and Communication Engineering is an ever growing industry in today’s digital scenario. Electronics and Communication Engineers engage in research, design, development and testing of the electronic equipment used in various communications systems.</p>
                                    </div>
        
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i> 210 students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> 4 Years
                                            </li>
                                            <li class="courses-price">
                                                <span>60 Intake</span>
                                           
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-5">
                                        <img src="assets/img/courses/courses5.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>Lina D'Souza</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Electrical & Electronics Engineering</a></h3>
                            
                                        <p>The Department works with the mission of providing our students with a high quality education so that they are well prepared to become high caliber Electrical and Electronics engineers and it aspires to grow to the level of gaining global recognition</p>
                                    </div>
        
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i> 210 students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> 4 Years
                                            </li>
                                            <li class="courses-price">
                                              60 Intake
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-6">
                                        <img src="assets/img/courses/courses6.jpg" alt="image">
                                        <a routerLink="/single-courses" class="link-btn"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/courses-list" class="d-block">Engineering</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                            <span>David Warner</span>
                                        </div>
                                        <h3><a routerLink="/single-courses" class="d-inline-block">Mechanical Engineering</a></h3>
                              
                                        <p>We aim to provide our students with a perfect blend of intellectual and practical experiences that helps them to serve our society and address a variety of needs.</p>
                                    </div>
        
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class='bx bx-user'></i> 210 students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class='bx bx-book-open'></i> 4 Years
                                            </li>
                                            <li class="courses-price">
                                               60 Intake
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                 
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Under Graduate Courses</h3>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog1.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Automobile Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog2.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Civil Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog3.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Computer Science Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog4.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Electronics and Communication Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog5.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Electrical and Electronics Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog6.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">B.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Mechanical Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Post Graduate Courses</h3>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog7.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">M.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Thermal Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog8.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">M.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Embedded System Technologies   </a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog9.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">M.E</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Computer Science Engineering</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                   
                      
                    </section>
                    <section class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Certified Courses</h3>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog7.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">Certified Course</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Certificate Program in Entrepreneurship

                                </a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog8.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">Certified Course</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Certificate Program in Event Management

                                </a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog9.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">Certified Course</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Certificate Program in Artificial Intelligence for Business</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                   
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span><img class="fullimage cover bg3" role="img" src="assets/img/blog/blog9.jpg" alt="image"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">Certified Course</time>
                                <h4 class="title usmall"><a routerLink="/single-courses">Certificate Program in Digital Marketing</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>


                    <section class="widget widget_instagram">
                        <h3 class="widget-title">Value Added Courses</h3>

                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog10.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog11.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog13.jpg" alt="image"></a></li>
                           
                        </ul>
                    </section>

                    <section class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Admission Enquiry</span>
                            <a href="tel:">+97866 56660</a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>