<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Question</h2>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
        <!--   <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Engineering College</span></a></li>
                <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Polytechnic College</span></a></li>
                <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Schools</span></a></li>
                <li><a href="#"><i class='bx bx-book-open'></i> <span>B.Ed Colleges</span></a></li>
                <li><a href="#"><i class='bx bx-info-circle'></i> <span>Teacher Training </span></a></li>
            </ul>-->

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Where is the school located?</h3>
                                <p><strong>A:</strong> Our school is located at Kalavasal, Sivagangai..</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Is it a coeducational school?</h3>
                                <p><strong>A:</strong> Yes, it is a coeducational school.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do you have audio - visual class rooms?</h3>
                                <p><strong>A:</strong> Yes, we have smart class room.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do you provide transport facility?</h3>
                                <p><strong>A:</strong> Yes, transport facility is available around 20 kms from the school.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is School Timings?</h3>
                                <p><strong>A:</strong> For KG to IX Std	: 8.50am to 3.50pm, For HSC		: 8.00am to 5.30pm .</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Is there any special coaching for board appearing students?</h3>
                                <p><strong>A:</strong> Special classes are conducted in the morning and evening for public sectors by experienced Teachers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the extra-curricular activities do you have?</h3>
                                <p><strong>A:</strong> Archery, Karate, Skating, Yoga, Western & Classical dance, instrumental music and Vocal.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do you have co-curricular activities? Does the school encourage sports? </h3>
                                <p><strong>A:</strong> Yes, we have various clubs like Language clubs, Quiz club, Eco-club, Scouts, etc.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How are parents involved with your school?</h3>
                                <p><strong>A:</strong> Parents are connected through Parent Teacher Association (PTA). </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is the office hour of the school?</h3>
                                <p><strong>A:</strong> The office functions from 8.30 am to 5.30 pm.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


