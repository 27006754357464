<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>

                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">St.Michael <br>College of Engineering & Technology, <br>St. Santhiagappar Nagar<br>
                                Kalayar koil - 630 551<br>
                                Sivagangai district<br>
                                Tamil Nadu
                                India</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="+91 04575 232009">     04575 232009,232010</a><br>
                            <a href="+91 91 99427 96746">+91 99427 96746</a><br>
                            <a href="+91 99427 96723">+91 99427 96723</a><br>
                            <a href="+91 73730 96716">+91 73730 96716</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:principalfmcet@gmail.com">office@smcet.edu.in </a><br>
                            <a href="mailto:principalfmcet@gmail.com">info@smcet.edu.in </a>
                        </li>
                        <li>
                            <i class='bx bx-paper-plane'></i>
                            <a href="Onlie Examination Enquiry">Online Examination Enquiry</a>
                            <a href="Onlie Examination Enquiry">+91 97866 56660</a>&nbsp; <br>
                            
                        </li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/stmichael.mhss/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/StMhss" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/stmichaelsvga/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCPlryFbr-4BGQ7aRxQ3tbRw?disable_polymer=true" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        
                    </ul>
                </div>
            </div>

           <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>

                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>-->
           
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>

  <ul class="useful-link">
                        <li><a href="https://stmichaelgroup.org/" class="d-block" target="_blank">www.stmichaelgroup.org</a></li>
                        <li><a href="http://smcet.edu.in/" class="d-block" target="_blank">www.smcet.edu.in</a></li>
                        <li><a href="http://www.fmcet.ac.in/" class="d-block" target="_blank">www.fmcet.ac.in</a></li>
                        <li><a href="http://www.stmichaelsvga.edu.in/" class="d-block" target="_blank">www.stmichaelsvga.edu.in</a></li>
                        <li><a href="https://stmichaelgroup.org/" class="d-block" target="_blank">www.stmichaelschoolmadurai.com</a></li>
                        <li><a href="https://rced.in/" class="d-block" target="_blank">www.rced.in</a></li>
          
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                 
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>

                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo1.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i> <a routerLink="/" target="_blank">St.Michael Group of Institutions</a> <a href="https://stmichaelgroup.org/" target="_blank"></a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
