<section class="features-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Infrastructure</h3>
                        <p></p>
                        <a routerLink="/" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Library </h3>
                        <p></p>
                        <a routerLink="/" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Association Activities</h3>
                        <p></p>
                        <a routerLink="/" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Sports & Games</h3>
                        <p></p>
                        <a routerLink="/" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Learn More</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slideshow-box">
        <ul class='slideshow-slides owl-carousel owl-theme'>
            <li><span class="bg3"></span></li>
            <li><span class="bg2"></span></li>
            <li><span class="bg1"></span></li>
            <li><span class="bg4"></span></li>
            <li><span class="bg5"></span></li>
        </ul>
    </div>
</section>