<div class="banner-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <span class="sub-title"></span>
                    <h1></h1>
                    <p></p>
                    <div class="btn-box">
                      
                       <br>
                       <br>
                       <br>       <br>
                       <br>
                       <br>
                       <br>
                       <br>
                       <br>
                       <br>
                       <br>
                       <span class="sub-title">our Campus Life video</span> <br>
                      
                       <a href="https://www.youtube.com/watch?v=_X1iOKvuJ0w" class="video-btn popup-youtube"><span><i class='bx bx-play-circle'></i></span> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider"></div>
</div>