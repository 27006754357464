<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Welcome </span>
                    <h2>St. Michael Matriculation Higher Secondary School</h2>
                    <h6 align = "justify">St.Michael Matriculation Hr. Sec. School takes the pleasure in welcoming you to explore our milestones.  We aim at creating next generation leaders by molding young learners, and developing responsible, self-confident individuals with a thirst for learning and caring. We take care of every child’s physical, mental and moral growth which can create new paradigms. 
                       </h6>
                    
                    <div class="features-text">
                       <!-- <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p align = "justify">We are happy that you have taken the right choice and now your priority becomes ours.</p>-->
                        <h5 ><i class='bx bx-paper-plane'></i>Vision</h5>
                        <p align = "justify">To equip our students to face the future challenges in technological developments while delivering the bounties of frontier knowledge for the benefit of mankind</p>
                        <h5><i class='bx bx-book-open'></i>Mission</h5>
                        <p align = "justify">To facilitate our students to learn and imbibe discipline, culture and spiritually besides encouraging them to acquire latest technological developments and also to emerge as a centre of excellence</p>
                    </div>
                    <a routerLink="/single-courses" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Admission</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
