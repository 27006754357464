<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="courses-2-columns-style-1.html"></a></li>
                <li></li>
            </ul>
            <h2>Academics</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <section >
        <div class="container">
            <div class="section-title">
<!--                <span class="sub-title">Academic Team</span>-->
                <h2>Our Team</h2>
            </div>
             <app-feedback-style-two></app-feedback-style-two>
        </div>
    </section>
    <div class="container">
           <div class="row">
            <div class="col-lg-8">
              <div class="courses-details-desc">
                    <h3>Curriculum</h3>
                             
                    <p align="justify">Our curriculum offers well-graded, age-appropriate content mapped to the NCERT syllabus and the National Curriculum Framework, thus taking care of all the learning needs of young leaders.</p>
                    <p align="justify">The textbooks are designed in such a manner that it contains the course content for each subject in a graded fashion. They are child-friendly, with explanations given, along with ample examples, interesting activities and attractive illustrations. Each subject is presented in a way that will appeal to learners and facilitators, with activity-based learning. The exercises are designed to enhance skills of application and analysis while developing multiple intelligences.</p>
                    <p align="justify">The digital content that is integrated with the content of the textbook includes audio recordings for auditory learners, animations for visual learners and interactive activities and concept-based games for kinaesthetic learners, thus embracing the Visual-Auditory-Kinaesthetic Approach.</p>
                    <p align="justify">Our curriculum offers the students a chance to lessen the load on students, by making their bags lighter and learning more interesting. </p>
                    <p align="justify">We are proud to present you this one-of-a-kind opportunity. There are several online courses teaching some of the skills related to the BI Analyst profession. The truth of the matter is that none of them completely prepare you.</p>
                    
                    <h3>Co-curricular Activities</h3>
                    <p><strong>Projects</strong></p>
                    <p align="justify">The habit of learning is practiced for our students by various assignments and activities in the form of projects. Projects provides opportunities for students to collaborate or drive their own learning. Also it moulds students' interpersonal skills. Students are grouped together to work for projects, which fosters communication skills and encourages even students with diverse and possibly conflicting personalities to find a common ground.</p>
                    <p><strong>Field Trips</strong></p>
                    <p align="justify">Educational Trips and Industrial Visits are undertaken with prior intimation to parents at regular intervals. Our students are energized by the excitement and anticipation of leaving the school environment. Students have the opportunity to see new things and learn about them in a more unstructured way.</p>
                  
                    
                    <p><strong>Extra-curricular Activities</strong></p>
                    <p align="justify">Club activities help the students learn and practice skills that will help them express their individuality. </p>
                    <p align="justify">To nurture the talent of each student, our school has made extensive arrangements that enable the students to learn different types of arts, crafts, music and dance. The school provides a rich variety of extra-curricular activities through Karate, Archery, Music, Dance, Skating and Yoga classes.</p>
                    <p align="justify">Students are bestowed with the privileges of exhibiting their talents through various cultural shows at school. This enables them to assume the role of management and leadership quality.</p>
                    <p><strong>“Educating the mind without educating the heart is no education at all”</strong></p>
   
                    <h3>Meet your Principal</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user0.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>Mr.S.Manuel</h4>
                                        <span class="d-block">Principal</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile</a>
                                    </div>
                                </div>
                            </div>
                            <p>“A child without education is like a bird without wings.”</p>
                            <p align="justify">I love the field of Education and I am passionate about this mission. St.Michael has a rich tradition of serving the society through education in a distinguished way. I am proud to be a part of it. I am sure each individual associated with the school feels so. We immensely value our tradition, imbibe values and learn lessons from it. Also we believe that excellence is the gradual result of always striving to do better. </p>
                            <p align="justify">We value relationships and connections with the community. We encourage our students to develop respectful relationships with their peers, teachers and the broader community</p>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                           <a><strong>X Standard Toppers</strong></a> 
                           
                        </li>
                    
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Kamalesh </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Joy Williams </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.Barkavi </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> P.Surithiga </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Saritha </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Yogavikash </span>
                            2019 – 2020
                        </li>
                        <li>
                            <a><strong>XII Standard Toppers</strong></a> 
                            
                         </li>

                        <li>
                            <span><i class='bx bxs-graduation'></i> I.JeyaSakthi</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.Keerthana</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.R.Madhumitha</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.MariyaNishalini</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> D.Anuciya</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> S.Phraphakumar</span>
                            2019 - 2020
                        </li>
                    </ul>
                </div>

                <div class="courses-sidebar-syllabus">
                    <h4>St.Michael Group of Institutions</h4>
                 
                   
                    <div class="courses-list">
                        <ul>
                            <br>
                            <li>
                                <a routerLink="/single-courses">
                                    <span class="number">1. St. Michael Higer Secondary School, </span> <br> P and T Nagar Ext Rd, Bama Nagar, Madurai, Tamil Nadu - 625017
                                </a>
                            </li>
                        <br>
                            <li>
                                <span class="number">2. St. Michael Higer Secondary School,</span> Kalavasal, Sivagangai, <br> Tamilnadu – 630 561
                            </li>
                            <br>
                            <li>
                                <span class="number">3. St. Michael Higer Secondary School,</span> <br> St. Santhiagappar Nagar, Kalayarkoil, <br> Tamilnadu - 630 551
                            </li>
                            <br>
                            <li>
                                <span class="number">4. Roseline College of Education,</span>  Kalavasal, Sivagangai, <br> Tamilnadu – 630 561
                            </li><br>
                            <li>
                                <span class="number">5. St. Michael Polytechnic College,</span> <br> St. Santhiagappar Nagar, Kalayar koil, <br> Tamilnadu - 630 551
                            </li><br>
                            <li>
                                <span class="number">6. Fatima Michael College of Engineering & Technology,</span> Senkottai Village, Madurai Sivagangai Main Road, Madurai, Tamilnadu - 625 020
                            </li>
                            <br>
                            <li>
                                <span class="number">7. St. Michael College of Engineering & Technology</span> <br> St. Santhiagappar Nagar, Kalayar koil, <br> Tamilnadu - 630 551
                            </li>

                        </ul>
                    </div>
                 
              
            
                </div>

         
            </div>
        </div>
    </div>
</section>
