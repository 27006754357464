
<app-header-style-one></app-header-style-one>

<app-homethree-main-banner></app-homethree-main-banner>
<!--
<section class="feedback-form-area">
    <div class="container">
        <app-feedback-form></app-feedback-form>
    </div>
</section>-->

<app-homethree-about></app-homethree-about>
<br>
<br>
<app-features></app-features>


<section class="partner-area pt-100">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</section>
<!--<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Management</h2>
            <p></p>
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>
-->

<section class="partner-area pt-100">
    <app-offer></app-offer>
    </section>

    <app-become-instructor-partner></app-become-instructor-partner>