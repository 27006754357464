<app-header-style-one></app-header-style-one>
<app-boxes></app-boxes>
<!-- <div class="page-title-area item-bg3 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Gallery</li>
            </ul>
            <h2>Gallery</h2>
        </div>
    </div>
</div> -->


<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery1.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery1.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery2.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery2.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery3.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery3.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery4.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery4.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery5.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery5.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery6.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery6.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery7.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery7.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery8.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery8.jpg">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item mb-30">
                    <img src="assets/img/gallery/gallery9.jpg" alt="Gallery Image" data-original="assets/img/gallery/gallery9.jpg">
                </div>
            </div>
        </div>
    </div>
</section>