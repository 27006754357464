<header class="header-area p-relative">
    <div class="navbar-area navbar-style-four">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/black-logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/black-logo.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="" href="javascript:void(0)" class="nav-link">Home</a>

                            </li>
                            <li class="nav-item"><a routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                            <li class="nav-item"><a routerLink="/academics" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Academics</a></li>

                      <!--  <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Institutions <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="#" class="nav-link">Engineering Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael College of Engineering & Technology</a></li>

                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Fatima Michael College of Engineering & Technology</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Arts & Science Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael College of Arts & Science</a></li>

                                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Fatima Michael College of Arts & Science</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Polytechnic Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Polytechnic College& </a></li>


                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Schools <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Madurai</a></li>

                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Sivagangai</a></li>

                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Kalayarkovil</a></li>



                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pope John Paul  Middle School, Ramnad</a></li>
                                        </ul>
                                    </li>



                                    <li class="nav-item"><a href="#" class="nav-link">B.Ed Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Roseline College of Education, Sivagangai</a></li>


                                        </ul>
                                    </li>







                                    <li class="nav-item"><a routerLink="/about-style-1"class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                                </ul>
                            </li>-->

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">All Events</a></li>

                                    <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>

                               </ul>
                            </li>





                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            <li class="nav-item"><a routerLink="/faqs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                        </ul>

                        <div class="others-option">
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                                    <span>Eng</span>
                                </button>


                            </div>


                            <div class="search-box d-inline-block">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
