<header class="header-area">

    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        
                                                <li>
                            
                            <i class='bx bx-phone-call'></i>
                            <span>Contact support</span>
                            <a href="tel:+91 99655 67022">+91 99655 67022</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Our location</span>
                            <a href="#">Sivagangai, Tamilnadu, INDIA</a>
                        </li>
         <li>
                                <i class='bx bx-envelope'></i>
                            <span>Contact email</span>
                            <a href="mailto:info@stmichaelgroup.org">stmichaelsvga@gmail.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4">
                    <div class="top-header-btn">
                        <a routerLink="/login" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Alumni</span><i class="bx bx-log-in icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                           
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="">
                        <img src="assets/img/black-logo1.png" alt="logo"> &nbsp;
                        <img src="assets/img/black-logo.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="" href="javascript:void(0)" class="nav-link">Home</a>
                                
                            </li>
                           
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">About Us <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Management</a></li>

                                    <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Facilities</a></li>

                               </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/academics" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Academics</a></li>
                            <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Admission</a></li>
                            
                           
                      <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Institutions <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="#" class="nav-link">Engineering Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael College of Engineering & Technology</a></li>

                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Fatima Michael College of Engineering & Technology</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Arts & Science Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael College of Arts & Science</a></li>

                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Fatima Michael College of Arts & Science</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link">Polytechnic Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Polytechnic College& </a></li>


                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Schools <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Madurai</a></li>

                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Sivagangai</a></li>

                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">St. Michael Matriculation Higher Secondary School, Kalayarkovil</a></li>



                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pope John Paul  Middle School, Ramnad</a></li>
                                        </ul>
                                    </li>



                                    <li class="nav-item"><a href="#" class="nav-link">B.Ed Colleges <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Roseline College of Education, Sivagangai</a></li>


                                        </ul>
                                    </li>







                                    <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                                </ul>
                            </li>

                           -->





                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            <li class="nav-item"><a routerLink="/faqs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                           
                        </ul>

                        <div class="others-option">
                            <div class="dropdown language-switcher d-inline-block">
                              <!--  <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                                    <img src="assets/img/logo.png" alt="image">
                                  
           


                            </div>


                            <div class="search-box d-inline-block">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>

</header>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
