<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-left">
                <h2>Our Values</h2>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                <h3>Vision</h3>
                <p align="justify">To equip our students to face the future challenges in technological developments while delivering the bounties of frontier knowledge for the benefit of mankind</p>
                <h3>Mission</h3>
                <p align="justify">To facilitate our students to learn and imbibe discipline, culture and spiritually besides encouraging them to acquire latest technological developments and also to emerge as a centre of excellence</p>
               <!-- <h3>Advancing human understanding</h3>
                <p>The knowledge of  social behavior and human understanding forms a solid foundation for a variety of careers. We prepare our students to gain the ability to analyze multiple perspectives on social and cultural change, assess conflicting points of view, and understand the interactions of individuals and societies. Thus, we transform a juvenile into a self-regulated man.</p>-->
            </div>
        </div>
    </div>
</div>
