<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                
            </ul>
            <h2>About Principal</h2>
        </div>
    </div>
</div>

<section class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="assets/img/instructor/instructor4.jpg" alt="image">

                        <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                        <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Mr.S.Manuel</h3>
                        <span class="sub-title">Principal</span>
                        <p>“A child without education is like a bird without wings.”</p>
                        <p><strong>Principal’s Message</strong></p>
                        <p>I love the field of Education and I am passionate about this mission. St.Michael has a rich tradition of serving the society through education in a distinguished way. I am proud to be a part of it. I am sure each individual associated with the school feels so. We immensely value our tradition, imbibe values and learn lessons from it. Also we believe that excellence is the gradual result of always striving to do better. </p>
                        <p>Our school aims to create a supportive and inclusive environment where gifted and talented students are encouraged to explore their potential and achieve their best in all aspects of school life. Students will be challenged and engaged through authentic learning opportunities that inspire them to develop creativity, confidence and resilience to become independent and ethical life-long learners.</p>
                        <p>We value relationships and connections with the community. We encourage our students to develop respectful relationships with their peers, teachers and the broader community.</p>
                        <p>We are proud to be a high performing school and aim to be the selective school of first choice. Our commitment will be for the betterment, common good and for the welfare of this institution and our society at large.</p>
                        <p>I wish God’s Blessing and my very best to each of the students</p>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>St.Michael Group</span>
                                            2000-2020
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>St.Michael Group</span>
                                            2020-2021
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    
    </div>
</section>