<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg3 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                
                
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                
                <li><a routerLink="/aboutus" ><i class='bx bxs-user'></i> Founder</a></li>
                <li><a routerLink="/chairman"><i class='bx bx-user'></i> Chairman </a></li>
                <li><a routerLink="/ceo" class="active"><i class='bx bx-user'></i> Correspondent</a></li>
                <li><a routerLink="/sec"><i class='bx bx-user'></i> Secretary</a></li>
               
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img src="assets/img/instructor/instructor2.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>Dr.S.Bridget Nirmala, M.E., Ph.D.</h3>
                        <p>Correspondent</p>
                        <p><strong>“Great personalities are shaped with great experiences.”</strong></p>
                        <p align="justify">A complete grown personality has to have a balance of physical, emotional, social and spiritual virtues. The new generation of children exhibit skills and talents of unparalleled genius. The future belongs to those who are Knowledgeable, Principled and Open minded.</p>
                        <p align="justify">Guided by these virtues, we run our programmers, keeping in mind the way to identify the inherent potential of a child and then to nurture it. St. Michael offers spectrum of activities leading to multiple experiences and enrichment of students by all means. Each of these activities are like the beads of a rosary, tied to a common thread – of Trust and Excellence.</p>
                        <p align="justify">The spacious, airy and naturally lighted classrooms in serene surroundings, led by experienced faculties form the core team of the academic programme. The best pedagogical practices along with excellent ICT support and world class infrastructure makes it convenient for the children to learn.</p>
                        <p><strong>“St.Michael School is an unparallel experience for life. We delightfully welcome you in to the Michaelites family.”</strong></p>
                        

                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>