<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
               
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/aboutus" ><i class='bx bxs-user'></i> Founder</a></li>
                <li><a routerLink="/chairman" class="active"><i class='bx bx-user'></i> Chairman </a></li>
                <li><a routerLink="/ceo"><i class='bx bx-user'></i> Correspondent</a></li>
                <li><a routerLink="/sec"><i class='bx bx-user'></i> Secretary</a></li>
               
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img src="assets/img/instructor/instructor1.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>Dr.Ln.M.Stalin Arockiaraj, B.E., M.S.</h3>
                        <p>Chairman</p>
                        <p><strong>“A nation will be known by its people and the people will be known by their education and their culture.”</strong></p>
                        <p align="justify">The 21st century holds for a bigger, better and brighter future. The scope for growth is tremendous. All this can be attributed to the technological awakening witnessed world over. India as a country a being recognized throughout the globe as a major centre of human resource center. Since half of India’s population is younger than 25, it gives the country a potential edge as a Young population – however whether India con benefit from its young population will depend on economic development and equitable social development.</p>
                        <p align="justify">Education will play an important role to a better economy. This will propel India to emerge as a world economic superpower by the year 2020. This can only be realized by importing quality education specially focused on development of “KASSM” (Knowledge, Attitude, Skills, Social and Moral Values).</p>
                        <p align="justify">Our institutions provide a learning environment that is adaptable and flexible, facilitating Potential changes in the higher education pattern. The learning environment is also used as a strategic tool to power and excel. This is done with an objective to support the new learning paradigm that provides learning for students in a manner most suited for them.</p>
                        <p><strong>“Train up a child in the way he should go and even when he is old he will not depart from it.”</strong></p>
                        <p align="justify">The challenge lies in utilizing the changing learning patterns with changes in methods of instructions coupled with technological advancements, to create on educational experience that will influence the all-round development of students and uphold our commitment towards nation building.</p>

                        
                    </div>
                </div>
            </div>
        </div>     
    </div>
</section>