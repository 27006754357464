<div class="testimonials-slides owl-carousel owl-theme">
    <div class="single-testimonials-item">
        <p>We are proud to be a high performing school and aim to be the selective school of first choice. Our commitment will be for the betterment, common good and for the welfare of this institution and our society at large</p>
        <div class="info">
            <img src="assets/img/user5.jpg" class="shadow rounded-circle" alt="image">
            <h3>Mr. John Britto Amalan </h3>
            <span>Director-Admin</span>
        </div>
    </div>

 <div class="single-testimonials-item">
        <p>I love the field of Education and I am passionate about this mission. St.Michael has a rich tradition of serving the society through education in a distinguished way. I am proud to be a part of it. I am sure each individual associated with the school feels so. We immensely value our tradition, imbibe values and learn lessons from it. Also we believe that excellence is the gradual result of always striving to do better. </p>
        <div class="info">
            <img src="assets/img/user7.jpg" class="shadow rounded-circle" alt="image">
            <h3>Mr.S.Manuel</h3>
            <span>Principal</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Our school aims to create a supportive and inclusive environment where gifted and talented students are encouraged to explore their potential and achieve their best in all aspects of school life. Students will be challenged and engaged through authentic learning opportunities that inspire them to develop creativity, confidence and resilience to become independent and ethical life-long learners.</p>
        <div class="info">
            <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image">
            <h3>Mrs.Jeyanthi </h3>
            <span>Headmister</span>
        </div>
    </div>


</div>
