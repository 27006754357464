<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Departments</li>
            </ul>
            <h2>Electronics & Communication Engineering</h2>
        </div>
    </div>
</div>



<section class="courses-details-area pt-100 pb-70">
    <div class="container">
           <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <!--<img src="assets/img/courses/courses2.jpg" alt="image">-->
                </div>
                
                <div class="courses-details-desc">
                    <h3>Faculty</h3>
                             
                    <p align="justify">The department is well steered by qualified and experienced faculty members with Research and Industrial background. Many of our faculty members are recognized for guiding Ph.D scholars of Anna University.</p>
                    

                    
                    <h3>About the Department</h3>
                   
                    <p align="justify">The department of Automobile Engineering was established in the year 2009 with an intake of 60 students. The department offers B.E. degree course in Automobile Engineering. The involvement spans a very broad spectrum of activities in the automotive field that covers experimentation and analysis of a wide range of technical applications.

                    </p>
                    <p><strong>Laboratory Facilities
                    </strong></p>
                    <p align="justify">The well equipped laboratories with modern machineries in the department offer opportunities for practical exposure and to understand the fundamentals concepts of Automobile Engineering. The laboratory is regularly updated with latest equipments that effectively complements research and innovation.

                    </p>

                    <p><strong>Association Activities
                    </strong></p>
                    <p align="justify">The association actively involves in conducting many motivation programmes for building self-confidence, right attitude, Placement training programmes, Personality development, Guest Lectures to enhance the technical skills of the students, Communication development programmes to enhance the speaking skill and Industrial visits to gain practical skills.

                    </p>
                    <p align="justify">The department encourages students to participate in co-curricular and extracurricular activities and guides to participate in Technical quizzes, Symposiums, Workshops, Seminars and Paper presentations.

                    </p>
                   
                    
                      </div>

            </div>

           <div class="col-lg-4">
                 <!--<div class="courses-sidebar-information">
                    <ul>
                        <li>
                           <a><strong>X Standard Toppers</strong></a> 
                           
                        </li>
                    
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Kamalesh </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Joy Williams </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.Barkavi </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> P.Surithiga </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Saritha </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Yogavikash </span>
                            2019 – 2020
                        </li>
                        <li>
                            <a><strong>XII Standard Toppers</strong></a> 
                            
                         </li>

                        <li>
                            <span><i class='bx bxs-graduation'></i> I.JeyaSakthi</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.Keerthana</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.R.Madhumitha</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.MariyaNishalini</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> D.Anuciya</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> S.Phraphakumar</span>
                            2019 - 2020
                        </li>
                    </ul>
                </div>-->

                <div class="courses-sidebar-syllabus">
                    <h3>Laboratory's</h3>
                 
                  
                    <div class="courses-list">
                        <ul>
                            
                            <li>
                                <a routerLink="/single-courses">
                                    <span class="number">1.</span> Automotive Components Laboratory
                                </a>
                            </li>
                        
                            <li>
                                <span class="number">2.</span> Emission Control And Testing Laboratory

                            </li>
                           
                            <li>
                                <span class="number">3.</span> Automotive Fuels and Lubricants Laboratory

                            </li>
                          
                            <li>
                                <span class="number">4.</span> Vehicle Maintenance Laboratory

                            </li>
                            <li>
                                <span class="number">5.</span> Automotive Engine Design Laboratory

                            </li>
                            <li>
                                <span class="number">6.</span> Automotive Chassis Design Laboratory

                            </li>
                          
                            <li>
                                <span class="number">7.</span> Strength of Materials Laboratory

                            </li>
                            <li>
                                <span class="number">8.</span> Fluid Mechanics & Machines Laboratory

                            </li>
                            <li>
                                <span class="number">9.</span> CAD/CAM Laboratory

                            </li>
                           
                        </ul>
                    </div>
                 
              
            
                </div>

         
            </div>
        </div>
    </div>
</section>

<!-- <section class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-shape-triangle'></i>
                        B.E. Automobile Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-font-family'></i>
                        B.E. Civil Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bxs-drink'></i>
                        B.E. Computer Science and Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-first-aid'></i>
                        B.E. Electrical and Electronics Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-bar-chart-alt-2'></i>
                        B.E. Electronics and Communication Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-briefcase-alt-2'></i>
                        B.E. Mechanical Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-book-reader'></i>
                        M.E. Computer Science and Engineering
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a routerLink="/">
                        <i class='bx bx-target-lock'></i>
                        M.E. Embedded System Technologies
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>
-->

