<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
               
               
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/aboutus" ><i class='bx bxs-user'></i> Founder</a></li>
                <li><a routerLink="/chairman"><i class='bx bx-user'></i> Chairman </a></li>
                <li><a routerLink="/ceo"><i class='bx bx-user'></i> Correspondent</a></li>
                <li><a routerLink="/sec" class="active"><i class='bx bx-user'></i> Secretary</a></li>
                
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img src="assets/img/instructor/instructor3.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>Ln.Mrs.M.Fatima Mary</h3>
                        <p>Secretary</p>
                        <p><strong>“Hearty Welcome to our St. Michael!.”</strong></p>
                        <p align="justify">Along with the academic excellence and vast sports opportunities, we provide our students with an exposure to the realities of the world. We sculpt them into optimistic and competitive individuals, who strive to succeed in every challenge they are faced with. We aim at ensuring that our comprehensive development Programs provide students with an international learning experience, while preserving our core Indian values.</p>
                        <p align="justify">With such qualities imbibed within them, our Students are bound to "Arise, Awake, Ascend" to greater heights and a greater tomorrow.</p>               
                    </div>
                </div>
            </div>
        </div> 
        
    </div>
</section>

