<section class="home-slides owl-carousel owl-theme">
    <div class="main-banner item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                     
                        <h1></h1>
                        <!--<p>We supports students by introducing collaborators outside R, internships and research experience abroad.</p>-->
                        <div class="btn-box">
                           
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                        <h1></h1>
                       <!-- <p>We supports students by introducing collaborators outside R, internships and research experience abroad.</p>-->
                        <div class="btn-box">
                            
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span class="sub-title"> </span>
                        <h1>  </h1>
                       <!-- <p>We supports students by introducing collaborators outside R, internships and research experience abroad.</p>-->
                        <div class="btn-box">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>