<div class="instructor-slides owl-carousel owl-theme">
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor0.jpg" alt="image">

            
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. V. Michael</a></h3>
            <span>Founder</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor1.jpg" alt="image">

            
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. M. Stalin Arockiaraj </a></h3>
            <span>CHARIMAN</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor2.jpg" alt="image">

            
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. S. Bridget Nirmala</a></h3>
            <span>CEO</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor3.jpg" alt="image">

            
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Mrs. M. Fatima Mary</a></h3>
            <span>SECRETARY </span>
        </div>
    </div>
</div>
