<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                               
            </ul>
            <h2>Academics</h2>
        </div>
    </div>
</div>
<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/director-admin" class="active"><i class='bx bxs-user'></i> Director-Admin</a></li>
                <li><a routerLink="/principal"><i class='bx bx-user'></i> Principal </a></li>
                <li><a routerLink="/hm"><i class='bx bx-user'></i> HeadMister</a></li>
               
                
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img src="assets/img/instructor/instructor4.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>Mr. John Britto Amalan</h3>
                        <p>Director-Ad,om</p>
                        <p><strong>“Hearty Welcome to our St. Michael!.”</strong></p>
                        <p align="justify">Along with the academic excellence and vast sports opportunities, we provide our students with an exposure to the realities of the world. We sculpt them into optimistic and competitive individuals, who strive to succeed in every challenge they are faced with. We aim at ensuring that our comprehensive development Programs provide students with an international learning experience, while preserving our core Indian values.</p>
                        <p align="justify">With such qualities imbibed within them, our Students are bound to "Arise, Awake, Ascend" to greater heights and a greater tomorrow.</p>               
                    </div>
                </div>
            </div>
        </div> 
        
    </div>
</section>
<section class="courses-details-area pt-100 pb-70">
    <div class="container">
           <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <!--<img src="assets/img/courses/courses2.jpg" alt="image">-->
                </div>
                
                <div class="courses-details-desc">
                    <h3>Curriculum</h3>
                             
                    <p align="justify">Our curriculum offers well-graded, age-appropriate content mapped to the NCERT syllabus and the National Curriculum Framework, thus taking care of all the learning needs of young leaders.</p>
                    <p align="justify">The textbooks are designed in such a manner that it contains the course content for each subject in a graded fashion. They are child-friendly, with explanations given, along with ample examples, interesting activities and attractive illustrations. Each subject is presented in a way that will appeal to learners and facilitators, with activity-based learning. The exercises are designed to enhance skills of application and analysis while developing multiple intelligences.</p>
                    <p align="justify">The digital content that is integrated with the content of the textbook includes audio recordings for auditory learners, animations for visual learners and interactive activities and concept-based games for kinaesthetic learners, thus embracing the Visual-Auditory-Kinaesthetic Approach.</p>
                    <p align="justify">Our curriculum offers the students a chance to lessen the load on students, by making their bags lighter and learning more interesting. </p>
                    <p align="justify">We are proud to present you this one-of-a-kind opportunity. There are several online courses teaching some of the skills related to the BI Analyst profession. The truth of the matter is that none of them completely prepare you.</p>
                    
                    <h3>Co-curricular Activities</h3>
                    <p align="justify"><strong>Projects</strong></p>
                    <p align="justify">The habit of learning is practiced for our students by various assignments and activities in the form of projects. Projects provides opportunities for students to collaborate or drive their own learning. Also it moulds students' interpersonal skills. Students are grouped together to work for projects, which fosters communication skills and encourages even students with diverse and possibly conflicting personalities to find a common ground.</p>
                    <p><strong>Field Trips</strong></p>
                    <p align="justify">Educational Trips and Industrial Visits are undertaken with prior intimation to parents at regular intervals. Our students are energized by the excitement and anticipation of leaving the school environment. Students have the opportunity to see new things and learn about them in a more unstructured way.</p>

                    <p><strong>Extra-curricular Activities</strong></p>
                    <p align="justify">Club activities help the students learn and practice skills that will help them express their individuality. </p>
                    <p align="justify">To nurture the talent of each student, our school has made extensive arrangements that enable the students to learn different types of arts, crafts, music and dance. The school provides a rich variety of extra-curricular activities through Karate, Archery, Music, Dance, Skating and Yoga classes.</p>
                    <p align="justify">Students are bestowed with the privileges of exhibiting their talents through various cultural shows at school. This enables them to assume the role of management and leadership quality.</p>
                    <p><strong>“Educating the mind without educating the heart is no education at all”</strong></p>        
                    
                      </div>

            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                           <a><strong>X Standard Toppers</strong></a> 
                           
                        </li>
                    
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Kamalesh </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Joy Williams </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.Barkavi </span>
                            2018 – 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> P.Surithiga </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> J.Saritha </span>
                            2019 – 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> K.Yogavikash </span>
                            2019 – 2020
                        </li>
                        <li>
                            <a><strong>XII Standard Toppers</strong></a> 
                            
                         </li>

                        <li>
                            <span><i class='bx bxs-graduation'></i> I.JeyaSakthi</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.Keerthana</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> M.R.Madhumitha</span>
                            2018 - 2019
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> R.MariyaNishalini</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> D.Anuciya</span>
                            2019 - 2020
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> S.Phraphakumar</span>
                            2019 - 2020
                        </li>
                    </ul>
                </div>

                <div class="courses-sidebar-syllabus">
                    <h3>SCHOOL INFO</h3>
                 
                    <h4>Rules & Regulations </h4>
                    <div class="courses-list">
                        <ul>
                            
                            <li>
                                <a routerLink="/single-courses">
                                    <span class="number">1.</span> A minimum of 75% attendance is compulsory to be eligible to appear in the examination
                                </a>
                            </li>
                        
                            <li>
                                <span class="number">2.</span> Students must come to school in neatly laundered uniform and polished shoes. 
                            </li>
                           
                            <li>
                                <span class="number">3.</span> Students are not allowed to use school phones without permission.
                            </li>
                          
                            <li>
                                <span class="number">4.</span> Respect for school property and school belongings is an absolute must.
                            </li>
                            <li>
                                <span class="number">5.</span> Students are not allowed to leave the school campus without out-pass and without permission of the Class teacher & Principal
                            </li>
                            <li>
                                <span class="number">6.</span> Every student must carry the school identity card each day of the school
                            </li>
                          
                            <li>
                                <span class="number">7.</span> Any communication sent in the school diary must be acknowledged by the parent/guardian
                            </li>
                            <li>
                                <span class="number">8.</span> English is second language for most of us, but it is also a medium of instruction. In order to acquire proficiency in spoken English, all students must compulsorily converse in English in school (and at home as far as possible)
                            </li>
                            <li>
                                <span class="number">9.</span> Students must report any incident of bullying and ragging to the teachers immediately
                            </li>
                            <li>
                                <span class="number">10.</span> Punctuality and regularity in attendance is essential. Students who have been absent the previous day must bring a note of explanation through a letter. In case of illness a Medical Certificate should also be sent
                            </li>
                        </ul>
                    </div>
                 
              
            
                </div>

         
            </div>
        </div>
    </div>
</section>
