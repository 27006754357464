<section class="how-it-works-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Join with Us</span>
            <h2>How to Join?</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <i class='bx bx-search-alt'></i>
                    </div>
                    <h3>Search Courses</h3>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <i class='bx bx-info-square'></i>
                    </div>
                    <h3>View Course Details</h3>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <i class='bx bx-like'></i>
                    </div>
                    <h3>Apply, Enroll or Register</h3>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
</section>